import React from 'react';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';
import MarkdownIt from 'markdown-it';
import s from '../../Feature.module.scss';

const parse = require('html-react-parser');

const md = new MarkdownIt({
  html: true,
  linkify: false,
});

const propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    hideTitle: PropTypes.bool,
    description: PropTypes.shape({ description: PropTypes.string.isRequired }),
    imageSliders: PropTypes.arrayOf(PropTypes.shape({ fluid: PropTypes.shape({}) })),
    backgroundColor: PropTypes.string,
    viewType: PropTypes.string,
  }).isRequired,
};

const ViewType5 = ({ item }) => {
  const { description } = item;
  const descriptionHtml = description?.description && md.render(description.description);

  const imageList = (item.imageSliders || []).slice();
  let lastImage;
  if (imageList.length % 2 !== 0) {
    lastImage = imageList.pop();
  }
  return (
    <div className={s.root}>
      <div
        className={[s.viewType5, s.container].join(' ')}
        style={{ background: item.backgroundColor }}
      >
        <div className={s.subContainer}>
          <div className={s.textBlock}>
            {!item.hideTitle && <h2>{item.title}</h2>}
            <div className={s.content}>{parse(descriptionHtml)}</div>
          </div>
        </div>
        <div className={s.imageSection}>
          {imageList.map((img) => (
            <div className={s.imageItem} key={`${img.id}${Math.random()}`}>
              <Image key={img.id} fluid={img.fluid} />
            </div>
          ))}
        </div>
        {lastImage && (
          <div className={s.lastImage}>
            <Image fluid={lastImage.fluid} />
          </div>
        )}
      </div>
    </div>
  );
};

ViewType5.propTypes = propTypes;
export default ViewType5;
