import React from 'react';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';
import StackGrid from 'react-stack-grid';
import MarkdownIt from 'markdown-it';
import s from '../../Feature.module.scss';

const parse = require('html-react-parser');

const md = new MarkdownIt({
  html: true,
  linkify: false,
});

const propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    hideTitle: PropTypes.bool,
    description: PropTypes.shape({ description: PropTypes.string.isRequired }),
    imageSliders: PropTypes.arrayOf(PropTypes.shape({ fluid: PropTypes.shape({}) })).isRequired,
    backgroundColor: PropTypes.string,
    viewType: PropTypes.string,
  }).isRequired,
  size: PropTypes.shape({ width: PropTypes.number }),
};

const defaultProps = {
  size: {
    width: 1024,
  },
};

const ViewType2 = ({ item, size }) => {
  const { description } = item;
  const descriptionHtml = description?.description && md.render(description.description);

  return (
    <div className={s.root}>
      <div
        className={[s.viewType2, s.container].join(' ')}
        style={{ background: item.backgroundColor }}
      >
        <div className={s.container}>
          <div className={s.subContainer}>
            <div className={s.textBlock}>
              {!item.hideTitle && <h2 className={s.title}>{item.title}</h2>}
              <div className={s.content}>{parse(descriptionHtml)}</div>
            </div>
          </div>
          <StackGrid columnWidth={size.width <= 768 ? '50%' : '25%'}>
            {item.imageSliders.map((img) => (
              <Image key={img.id} fluid={img.fluid} />
            ))}
          </StackGrid>
        </div>
      </div>
    </div>
  );
};

ViewType2.propTypes = propTypes;
ViewType2.defaultProps = defaultProps;
export default ViewType2;
